<script setup lang="ts">

import {computed, onMounted, ref, watch} from "vue";
import {IInvoiceDetails} from "@/services/http.service.ts";
import {toast} from "vue3-toastify";
import {supabase} from "@/supabase.ts";
import {useI18n} from "vue-i18n";
import {useRoute} from "vue-router";
import {createStripeSubscription} from "@/services/stripe.service.ts";
import {CFormCheck, CFormLabel, CFormSelect} from "@coreui/vue";
import {getData as getCountries} from "country-list";
import {useGtag} from "vue-gtag-next";
import {useProfile, useUser} from '@/queries/userQuery.ts'
import { SubscriptionId } from "../../../model";

const {t} = useI18n()
const route = useRoute()
const errorMap: any = ref({});
const subscriptionType = ref<SubscriptionId>(null);
const { data: user } = useUser();
const { data: profile } = useProfile();

const countries = computed(() => {
  return getCountries().map((country) => {
    return {
      label: country.name,
      value: country.code,
    }
  })
})

const { event } = useGtag();

function initInputValues() {
  subscriptionType.value = route.query.subscriptionType as SubscriptionId;

  formData.value.fullName = profile.value?.full_name;
  formData.value.email = user.value?.email;
}
onMounted(() => {
  initInputValues();
})

watch([user,profile],() => {
  initInputValues();
})

const readTOC = ref(false);

const formData = ref<IInvoiceDetails>({
  email: '',
  fullName: '',
  country: 'CZ',
  city: '',
  postalCode: '',
  address1: '',
  isCompany: false,
  companyName: '',
  ic: '',
  dic: '',
});

const makePayment = async () => {
  if (validateForm()) {
    const res = await createStripeSubscription(subscriptionType.value,
        {
          fullName: formData.value.fullName,
          email: formData.value.email,
          country: formData.value.country,
          postalCode: formData.value.postalCode,
          city: formData.value.city,
          address1: formData.value.address1,
          isCompany: formData.value.isCompany,
          companyName: formData.value.companyName,
          ic: formData.value.ic,
          dic: formData.value.dic,
        });

    const {checkoutUrl} = await res.json();

    if (!checkoutUrl) {
      toast.error(t('payment.error.unknown'));
    } else {
      window.location.href = checkoutUrl;
    }
  } else {
    Object.values(errorMap.value).forEach((errorMessage: string) => {
      toast.error(errorMessage)
    })
  }
}


const validateForm = () => {
  let valid = true;

  if (formData.value.fullName?.length <= 1) {
    errorMap.value.fullName = t('payment.error.fullName.required');
    valid = false;
  } else {
    delete errorMap.value.fullName
  }

  if (formData.value.email?.length <= 1) {
    errorMap.value.email = t('payment.error.email.required');
    valid = false;
  } else {
    delete errorMap.value.email
  }

  if (formData.value.address1?.length <= 1) {
    errorMap.value.address1 = t('payment.error.address1.required');
    valid = false;
  } else {
    delete errorMap.value.address1
  }

  if (formData.value.city?.length <= 1) {
    errorMap.value.city = t('payment.error.city.required');
    valid = false;
  } else {
    delete errorMap.value.city
  }

  if (formData.value.postalCode?.length <= 1) {
    errorMap.value.postalCode = t('payment.error.postalCode.required');
    valid = false;
  } else {
    delete errorMap.value.postalCode
  }

  if (!readTOC.value) {
    errorMap.value.toc = t('payment.error.toc.required');
    valid = false;
  } else {
    delete errorMap.value.toc
  }

  return valid;
}
</script>

<template>
  <CCard class="p-3  mt-3">
    <h2>{{ $t('welcome.invoice.header') }}</h2>
    <CCardBody class="d-flex flex-column">
      <CForm
          @submit.prevent="makePayment"
          class="d-flex flex-column"
      >
        <CFormLabel>
          <span class="required">*</span> {{ $t('user.full_name') }}
          <CFormInput
              type="text"
              class="mb-2"
              autocomplete="full-name"
              v-model="formData.fullName"
          />
        </CFormLabel>
        <CFormLabel>
          <span class="required">*</span> {{ $t('user.email') }}
          <CFormInput
              type="email"
              disabled
              class="mb-2"
              autocomplete="email"
              :value="formData.email"
          />
        </CFormLabel>
        <CFormLabel>
          <span class="required">*</span> {{ $t('user.address1') }}
          <CFormInput
              type="text"
              class="mb-2"
              autocomplete="address-line1"
              v-model="formData.address1"
          />
        </CFormLabel>
        <CFormLabel>
          <span class="required">*</span> {{ $t('user.city') }}
          <CFormInput
              type="text"
              class="mb-2"
              name="city"
              autocomplete="home city"
              v-model="formData.city"
          />
        </CFormLabel>
        <CFormLabel>
          <span class="required">*</span> {{ $t('user.postalCode') }}
          <CFormInput
              type="text"
              class="mb-2"
              autocomplete="postal-code"
              v-model="formData.postalCode"
          />
        </CFormLabel>

        <CFormLabel>
          <span class="required">*</span> {{ $t('user.country') }}
          <CFormSelect
              type="text"
              class="mb-2"
              :options="countries"
              autocomplete="country"
              v-model="formData.country"
          />
        </CFormLabel>
        <CFormCheck
            type="checkbox"
            class="mb-2"
            v-model="formData.isCompany"
            id="company"
            :label="$t('user.company')"
        />

        <template v-if="formData.isCompany">
          <CFormInput
              class="mb-2"
              type="text"
              v-model="formData.companyName"
              :label="$t('user.company_name')"
          />
          <CFormInput
              type="number"
              class="mb-2"
              v-model="formData.ic"
              :label="$t('user.ic')"
          />
          <CFormInput
              type="text"
              class="mb-2"
              v-model="formData.dic"
              :label="$t('user.dic')"
          />
        </template>

        <img class="logo-cards d-flex align-self-center mb-3 mt-5" src="/loga-karet.png" alt="loga karet"/>

        <div class="d-flex mb-2">
          <CFormCheck id="TOC" v-model="readTOC"/>&nbsp;
          <label for="TOC"><span class="required">*</span> {{ $t('payment.toc.text') }}</label>&nbsp;
          <a href="https://bluestream.ai/obchodni-podminky/" target="_blank">{{ $t('payment.toc.link') }}</a>
        </div>

        <div class="text-center">
          <CButton class="align-self-center" color="primary" type="submit">{{ $t('payment.submit') }}</CButton>
        </div>
        <div class="text-center mt-2">
          <CButton @click="$router.push({ name: 'introSubscription' })">{{ $t('intro.back') }}</CButton>
        </div>

      </CForm>
    </CCardBody>
  </CCard>
</template>

<style scoped lang="scss">
.logo-cards {
  width: 300px;
  display: inline-block;
}

</style>