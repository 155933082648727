import {useQuery} from '@tanstack/vue-query'
import {supabase} from '@/supabase.ts'
import {ProfileRow} from '@/types/supabase.types.ts'

const fetchProfile = async (): Promise<ProfileRow> => {
    const { data } = await supabase
        .from('profile')
        .select()
        .single()

    return data;
}

export const useProfile = () => useQuery({
    queryKey: ['profile'],
    queryFn: fetchProfile
})

export const useSubscriptionFeatureList = () => {
    return useQuery({
        queryKey: ['subscriptionFeatureList'],
        queryFn: async () => {
            const { data } = await supabase.auth.getUser()

            const {data: subscriptionFeatureData, error} = await supabase
                .from('stripe_customer')
                .select('subscription_features')
                .eq('uid', data.user.id)
                .single()

            if (error) return [];

            return JSON.parse(subscriptionFeatureData?.subscription_features || '[]');
        },
    })
}

export const useUser = () => useQuery({
    queryKey: ['user'],
    queryFn: async () => {
        const { data, error } = await supabase.auth.getUser()

        console.log('useUser DATA Called', data, error);
        return data?.user;
    }
})
