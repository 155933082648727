<script setup lang="ts">
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {computed, ref} from "vue";
import {useTokenStore} from "@/store/token.ts";
import {formatDate} from "@/util/date.ts";
import {CButton, CTableDataCell} from "@coreui/vue";
import DiscordSvg from '../../assets/discord-icon.svg';

const router = useRouter();

const openDiscord = () => {
  window.open('https://discord.gg/yuSr2nzsed','_blank');
}

</script>

<template>
  <h2>{{ $t('settings.support.header') }}</h2>
  <p>{{ $t('settings.support.text') }}</p>
  <CRow :lg="{ cols: 3, gutter: 4 }" :xs="{ cols: 1, gutter: 3 }" class="settings-support">
<!--    <CCol>-->
<!--      <CCard class="h-100">-->
<!--        <CCardBody class="d-flex flex-column">-->
<!--          <h4>{{ $t('settings.support.discord.header') }}</h4>-->
<!--          <p>{{ $t('settings.support.discord.text') }}</p>-->
<!--          <CButton color="secondary" target="_blank" @click="openDiscord">-->
<!--            <DiscordSvg class="discord-icon"/>&nbsp;{{ $t('sideMenu.discord') }}-->
<!--          </CButton>-->
<!--        </CCardBody>-->
<!--      </CCard>-->
<!--    </CCol>-->
    <CCol>
      <CCard class="h-100">
        <CCardBody class="d-flex flex-column">
          <h4>{{ $t('settings.support.email.header') }}</h4>
          <p>{{ $t('settings.support.email.text') }}</p>
          <a href="mailto:support@bluestream.ai" class="text-center mt-1">support@bluestream.ai</a>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>

</template>

<style lang="scss">

.settings-support {
  .discord-icon {
    width: 30px;
    height: 30px;
  }

  &__list {
    padding: 0;
    list-style: none;

    li {
      text-decoration: none;

    }
  }
}


</style>
