<script setup lang="ts">

import {computed, onMounted, reactive, ref, watch} from "vue";
import TestAreaGrowable from "@/components/Atoms/TextAreaGrowable/TestAreaGrowable.vue";
import {storeToRefs} from "pinia";
import {AIContentTypes, useChatStore} from "@/store/chat.ts";
import {CIcon} from "@coreui/icons-vue";
import PromptContentType from "@/components/Atoms/PromptContentType/PromptContentType.vue";
import {CForm} from "@coreui/vue";
import LoadingButton from "@/components/Atoms/LoadingButton/LoadingButton.vue";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {useSubscriptionAccess} from "@/composables/useSubscriptionUtils.ts";
import ChatFilter from "@/components/templates/Chat/ChatFilter.vue";
import {useChatGenerate} from "@/composables/useChatGenerate.ts";
import {TourName, useTour} from "@/store/tour.ts";

const content = ref('');
const length = ref(2);
const style = ref('');

const {stopGenerating} = useChatGenerate();
const {generatingInProgress} = storeToRefs(useChatStore());

const {chat, chatId, filters} = defineProps<{
  filters: Object[],
  chat: any,
  chatId: string,
}>();


const chatStore = useChatStore();
const {subscription, isActive, product} = storeToRefs(useSubscriptionStore());
const {hasGeneralAccess} = useSubscriptionAccess();
const {filterListByContentType} = storeToRefs(chatStore);

const promptForm = ref(null);

const contentType = computed(() => {
  return chat?.value?.content_type;
})

const {promptInputHandler} = useChatStore();
const emit = defineEmits(['submit']);

promptInputHandler.register((prompt: string) => {
  content.value = prompt;
}, 'prompt')

const onSubmit = async () => {
  emit('submit', content.value);
  content.value = '';
}

const onEnter = (event) => {
  if (!isActive.value || generatingInProgress[chatId.value]?.content) {
    event.preventDefault();
    return;
  }

  if (!event.shiftKey) {
    event.preventDefault();
    onSubmit();
  }
}

onMounted(() => {
  const { startTourByName } = useTour();

  startTourByName(TourName.textContent);
})

</script>

<template>
  <div class="chat-prompt d-flex" id="tour-content-text-prompt">
    <PromptContentType
        :content-type="contentType"
        v-if="contentType"
    />
    <CForm @submit.prevent="onSubmit"
           class="w-100"
           ref="promptForm"
    >
      <section class="chat-prompt__filter">
        <ChatFilter v-for="filter in filterListByContentType?.[contentType?.type]"
                    :name="filter.name"
                    v-model="filters[filter.name]"
                    :options="filter.values"/>
      </section>
      <CRow class="chat-prompt__entry">
        <TestAreaGrowable
            v-model="content"
            @keydown.enter="onEnter"
            :label="$t('chat.placeholder')"/>
        <LoadingButton
            v-if="!generatingInProgress?.[chatId]?.content || contentType.type === AIContentTypes.IMAGE"
            color="primary"
            id="chat-prompt-submit"
            class="chat-prompt__prompt-button"
            :loading="generatingInProgress?.[chatId]?.content"
            :disabled="!hasGeneralAccess || content.length === 0 || generatingInProgress?.[chatId]?.suggestions"
            type="submit"
        >
          <CIcon icon="cilSend"/>
        </LoadingButton>
        <CButton v-else
                 class="chat-prompt__prompt-button"
                 @click="stopGenerating(chatId)"
                 :disabled="!hasGeneralAccess"
                 color="danger">
          <CIcon icon="cilBan"/>
        </CButton>
      </CRow>
    </CForm>
  </div>
</template>

<style lang="scss">
.chat-prompt {
  background: white;
  margin: auto auto 0 auto;
  padding: 10px 0;
  box-shadow: 0 3px 34px -22px rgb(74, 114, 233);
  border: 1px solid #f1f1f1;
  display: flex;
  justify-content: center;
  border-radius: 20px;
  margin-bottom: 20px;
  z-index: 102;

  &__filter {
    position: absolute !important;
    top: -100%;
    left: 0;
    padding: 10px;
  }

  &__prompt-button {
    padding: 5px !important;
  }

  &__entry {
    position: relative;

    button {
      position: absolute;
      width: 70px;
      right: 35px;
      bottom: -3px;
    }
  }

  @media (max-width: 768px) {
    &__entry {
      button {
        width: 45px;
      }
    }
  }
}
</style>
