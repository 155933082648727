<script setup lang="ts">

import {CContainer} from "@coreui/vue"
import {useChatStore} from "@/store/chat.ts";
import {useRouter} from "vue-router";
import {onMounted} from "vue";
import ContentTypeIcon from "@/components/Atoms/ContentTypeIcon/ContentTypeIcon.vue";
import {TourName, useTour} from "@/store/tour.ts";
import {useTemplateCategoryList, useTemplateList} from '@/queries/templateQuery.ts'

const chatStore = useChatStore();
const router = useRouter();
const {data: templateList} = useTemplateList();
const {data: templateCategoryList} = useTemplateCategoryList();

const createNewChat = async (contentType: string) => {
  const {data: {id: chatId}} = await chatStore.createNewChat(contentType);

  await router.push(`/chat/${chatId}`);
  await chatStore.getChatList();
}

const routeToTemplateByName = async (templateName: string) => {
  if (['chat', 'image'].includes(templateName)) {
    return await createNewChat(templateName);
  }

  return await router.push(`/template/${templateName}`);
}

onMounted(() => {
  const { startTourByName } = useTour();

  startTourByName(TourName.templates);
})

</script>

<template>
  <CContainer class="templates">
    <CRow
        v-for="{ name:category } in templateCategoryList"
        :id="`tour-category-${category}`"
        class="mb-5 mt-2 templates__section" :xs="{ cols: 3, gutter: 4}" :md="{ cols:4 }" :lg="{ cols: 4 }">
      <h4 class="text-center w-100">{{ $t(`category.${category}.header`) }}</h4>
      <p class="m-0 text-center w-100">{{ $t(`category.${category}.info`) }}</p>

      <template v-for="template in templateList">
        <CCol xs v-if="template.category?.name === category">
          <div :id="`template-${category}`"
               class="templates__item d-flex align-items-center flex-column"
               @click="routeToTemplateByName(template?.key)">

<!--               @click="createNewChat(template.type)"-->
<!--          >-->
            <ContentTypeIcon :id="`content-${template?.key}`" size="lg" :type="template.key"/>
            <span class="templates__name mt-2 text-center">{{ $t(`template.${template.key}`) }}</span>
          </div>
        </CCol>
      </template>
    </CRow>
  </CContainer>
</template>

<style scoped lang="scss">
.templates {
  &__item {
    font-size: 12px;
    cursor: pointer;
  }

  &__section {
    padding-bottom: 60px;
    margin: 10px;
    border-radius: 30px;
    box-shadow: 0 0 23px -11px #c8c8c8;
  }

  @media (max-width: 768px) {
    padding-top: 0 !important;

    &__section {
      padding: 0 !important;
      box-shadow: none;
      margin: 0 !important;
      margin-bottom: 20px !important;
    }

    h4 {
      margin-bottom: 0;
    }
  }
}
</style>
