import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {supabase} from "@/supabase.ts";
import {IMAGE_TRIAL_LIMIT} from "@/constants/variables.ts";

export const useTokenStore = defineStore('token', () => {
    const tokens = ref(0);
    const imageCount = ref(0);
    const purchaseTypes = ref([]);
    const tokenMovements = ref([]);

    const getTokens = async () => {
        console.log('getTokens', getTokens);
        const response = await supabase
            .from('token')
            .select();

        tokens.value = response.data?.[0]?.token_count;
    }

    const getPurchaseTypes = async () => {
        const response = await supabase
            .from('purchase_type')
            .select()

        purchaseTypes.value = response.data;
    }

    const getTokensMovement = async () => {
        const response = await supabase
            .from('token_movement')
            .select()
            .order('created_at', {ascending: false})

        tokenMovements.value = response.data;
    }

    const hasTokens = computed<boolean>(() => {
        return true;
        // return tokens.value > 0;
    })

    const getImageGeneratedCount = async () => {
        const {data: {user: {id}}} = await supabase.auth.getUser();

        const {data: imageGeneration} = await supabase.from('token_movement')
            .select('uid, reason')
            .eq('uid', id)
            .eq('reason', 'image')

        console.log('imageGeneration', imageGeneration);
        imageCount.value = imageGeneration.length;
    }

    const hasImages = computed(() => {
        return imageCount.value < IMAGE_TRIAL_LIMIT;
    });

    return {tokens, hasTokens, tokenMovements, purchaseTypes, hasImages, getImageGeneratedCount, getPurchaseTypes, getTokens, getTokensMovement};
})