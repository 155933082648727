<script setup lang="ts">
import {useRouter} from "vue-router";
import Avatar from "@/components/Atoms/Avatar/Avatar.vue";
import {CButton, CCol, CPlaceholder, CRow} from "@coreui/vue";
import {useUserStore} from "@/store/user.ts";
import {storeToRefs} from "pinia";
import {onMounted, ref, watch} from "vue";
import {updateUserInfo} from "@/services/http.service.ts";
import {useI18n} from "vue-i18n";
import {toast} from "vue3-toastify";
import TrialCard from "@/components/Atoms/TrialCard/TrialCard.vue";
import {useTokenStore} from "@/store/token.ts";
import {supabase} from "@/supabase.ts";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {DateFormatType, formatDate} from "@/util/date.ts";
import DashboardButtonWidget from "@/components/Atoms/DashboardButtonWidget/DashboardButtonWidget.vue";
import DashboardProgressWidget from "@/components/Atoms/DashboardProgressWidget/DashboardProgressWidget.vue";
import {OpenAIRole} from "@/store/chat.ts";
import {useUIStore} from "@/store/ui.ts";
import {useProfile, useUser} from '@/queries/userQuery.ts'

const router = useRouter();
const { data: profile } = useProfile();
const { data: user } = useUser();
const tokenStore = useTokenStore();
const subscriptionStore = useSubscriptionStore();
const {subscription, product, isTrial, isActive} = storeToRefs(subscriptionStore);
const {t} = useI18n();
const {tokens} = storeToRefs(tokenStore);

const fullName = ref('')

const handleSignOut = async () => {
  const {error} = await supabase.auth.signOut()

  if (error) {
    console.error(error)
  }

  await router.push({name: 'signIn'})
}

const prepopulateUserInfo = async () => {
  if (!fullName.value) {
    console.log('profile', profile);
    fullName.value = profile.value?.full_name;
  }
}

onMounted(() => {
  prepopulateUserInfo();
})

watch(profile, async () => {
  prepopulateUserInfo();
})

const {installedVersion} = useUIStore();

const renewModalVisibility = ref(false);
const renewNextModalVisibility = ref(false);

const onSubmit = async () => {
  await updateUserInfo({fullName: fullName.value})
  toast.success(t('settings.profile.updated'))
}

</script>

<template>
  <!--  <pre>-->
  <!--  product: {{product}}-->
  <!--  trialend: {{subscription.trial_end}}-->
  <!--  current_period_end: {{formatDate(subscription.current_period_end * 1000)}}-->
  <!--  current_period_start: {{formatDate(subscription.current_period_start * 1000)}}-->
  <!--  cancel_at_period_end: {{subscription.cancel_at_period_end }}-->
  <!--  billing_cycle_anchor: {{formatDate(subscription.billing_cycle_anchor * 1000)}}-->
  <!--  cancel_at: {{formatDate(subscription.cancel_at * 1000)}}-->
  <!--  latest_invoice: {{subscription.latest_invoice}}-->
  <!--  status (trialing, active, canceled): {{subscription.status}}-->

  <!--  </pre>-->
  <CRow>
    <CCol class="d-flex flex-row mb-4">
      <Avatar size="xl" :role="OpenAIRole.USER"/>
      <h2 class="mx-2 align-items-center d-flex">{{ $t('dashboard.welcome') }}, {{ user?.full_name }}</h2>
    </CCol>
  </CRow>
  <template v-if="subscription">
    <CRow :lg="{ cols: 1 }"
          class="mb-4">
      <CCol>
        <!--    Cancel is scheduled-->
        <DashboardButtonWidget
            v-if="subscription?.cancel_at_period_end && subscription?.status !== 'canceled'"
            :color="`warning`"
            :header="$t('dashboard.subscription.canceled.scheduled.header')"
            :text="$t('dashboard.subscription.canceled.scheduled.text')"
            :button-text="$t('subscription.renew.submit')"
            @submit="renewNextModalVisibility = true"
        />
        <!--    Canceled  -->
        <DashboardButtonWidget
            v-else-if="subscription?.status === 'canceled'"
            :color="`warning`"
            :header="$t('dashboard.subscription.canceled.header')"
            :text="$t('dashboard.subscription.canceled.text')"
            :button-text="$t('subscription.renew.submit')"
            @submit="renewModalVisibility = true"
        />
        <!--    Paused  -->
        <DashboardButtonWidget
            v-else-if="subscription?.status === 'paused'"
            :color="`warning`"
            :header="$t('dashboard.subscription.paused.header')"
            :text="$t('dashboard.subscription.paused.text')"
            :button-text="$t('subscription.renew.submit')"
            @submit="renewModalVisibility = true"
        />
      </CCol>
    </CRow>
    <CRow :xs="{ cols: 1, gutter: 4}" :md="{ cols:3 }" :lg="{ cols: 3 }"
          class="mb-4">
      <template v-if="subscription?.status">
        <CCol>
          <DashboardProgressWidget
              :header="product?.name"
              :text="subscription?.current_period_end ? `${$t('dashboard.subscription.text')} ${formatDate(subscription?.current_period_end * 1000, DateFormatType.DATE)}`: ''"
              :progress="(tokens / subscription?.token_count) * 100"
              :sub-text="$t('dashboard.subscription.subtext')"
          />
        </CCol>
<!--        <CCol>-->
<!--          <CWidgetStatsB-->
<!--              class="h-100"-->
<!--              :progress="{ color: 'success', value: (tokens / subscription?.currentType?.token_count) * 100}"-->
<!--          >-->
<!--            <template #text>-->
<!--              <span v-if="subscription?.status !== 'trialing'">{{ $t('dashboard.tokens.text') }}</span>-->
<!--              <span v-else>{{ $t('dashboard.tokens.trialText') }}</span>-->
<!--            </template>-->
<!--            <template #title>-->
<!--              <span v-if="subscription?.status !== 'trialing'">{{ $t('dashboard.tokens.title') }}</span>-->
<!--              <span v-else>{{ $t('dashboard.tokens.trialTitle') }}</span>-->
<!--            </template>-->
<!--            <template #value>-->
<!--              <Number :value="tokens"/>-->

<!--            </template>-->
<!--          </CWidgetStatsB>-->
<!--        </CCol>-->
      </template>
      <template v-if="isTrial || !isActive">
      <CCol v-if="isTrial">
        <TrialCard/>
      </CCol>
      <CWidgetStatsB
          @click="$router.push({ name: 'introWelcome' })"
          :inverse="true"
          color="primary"
          class="trial-card pointer"
      >
        <template #text>{{ $t('subscription.noSubscription.cardText') }}</template>
        <template #title>{{ $t('subscription.noSubscription.cardLabel') }}</template>
        <template #value>
          {{ $t('subscription.noSubscription.header') }}
        </template>
      </CWidgetStatsB>
      </template>
    </CRow>
  </template>
  <CPlaceholder v-else component="p" animation="glow">
    <CPlaceholder color="secondary" :xs="12" size="m"/>
  </CPlaceholder>
  <CRow v-if="user">
    <CCol class="d-flex flex-row mb-5">
      <CForm class="row"
             @submit.prevent="onSubmit"
      >
        <CCol xs="12" class="mb-3">
          <CFormInput
              v-model="fullName"
              type="text"
              :label="$t('user.full_name')"
          />
          <CFormInput
              v-model="user.email"
              type="email"
              disabled
              :label="$t('user.email')"
          />
        </CCol>
        <!--        <CCol xs="12" class="mb-3">-->
        <!--          <CFormSelect-->
        <!--              aria-label="Default select example"-->
        <!--              label="Jazyk"-->
        <!--              :options="[-->
        <!--                { label: 'Čeština', value: 'cs' },-->
        <!--                { label: 'Angličtina', value: 'en' }-->
        <!--              ]">-->
        <!--          </CFormSelect>-->
        <!--        </CCol>-->
        <CCol xs="12">
          <CButton color="primary" type="submit">{{ $t('ui.save') }}</CButton>
        </CCol>
      </CForm>
    </CCol>
  </CRow>

  <CRow>
    <CCol class="settings-profile__version">
      {{ `${$t('ui.version')}: ${installedVersion}` }}
    </CCol>
    <CCol class="justify-content-end d-flex align-items-center align-content-end">
      <CButton color="secondary"
               id="nav-item_signout"
               @click="handleSignOut">
        <CIcon size="sm"
               icon="cilAccountLogout"/>
        {{ $t('settings.signout') }}
      </CButton>
    </CCol>
  </CRow>
</template>

<style scoped lang="scss">

.settings-profile {
  &__version {
    color: grey;
  }
}
</style>
