import {defineStore, storeToRefs} from "pinia";
import {computed, ref} from "vue";
import {
    getPriceList,
    getProductList, getStripeProduct,
    getStripeSubscription,
} from "@/services/stripe.service.ts";
import Stripe from "stripe";
import {useUserStore} from "@/store/user.ts";
import {addDaysToDate, isDate1AfterDate2} from "@/util/date.ts";
import {TRIAL_PERIOD_DAYS} from "@/constants/variables.ts";
import {supabase} from '@/supabase.ts'

export interface BMSubscription {
    uid: string,
    type: string,
    starts_at: string,
    currentType: {
        id: string,
        period: number,
        payment_frequency: 'string',
        token_count: number,
    }
    nextType: {
        id: string,
        period: number,
        payment_frequency: 'string',
        token_count: number,
    }
}

export interface BMSubscriptionType {
    id: string,
    token_count_type: number,
    productId: number,
    metadata: {
        features: string[]
    },
    period: number
    recurring: string
    token_count: number
}

export const useSubscriptionStore = defineStore('subscription', () => {
    const subscription = ref<Stripe.Subscription>(null);
    const productList = ref<BMSubscriptionType[]>([] as BMSubscriptionType[]);
    const userSubscriptionFeatureList = ref<string[]>([] as string[]);
    const { user } = storeToRefs(useUserStore());

    const getProductById = async (productId) => {
        const response = await getStripeProduct(productId);
        
        return response.json();
    }

    const getSubscription = async () => {
        const subscriptionResponse = await getStripeSubscription();
        const subscriptionResponseContent = await subscriptionResponse.json();

        if (subscriptionResponseContent.ok === false) {
            subscription.value = {} as Stripe.Subscription;
            return;
        }

        subscription.value = subscriptionResponseContent
    };

    const product = computed(() => productList.value.find((product) => {
            return product.productId === subscription.value?.plan?.product;
        }
    ));

    const getSubscriptionPriceList = async () => {
        const responsePrice = await getPriceList();
        const responseProducts = await getProductList();

        let {data: priceData} = await responsePrice.json();
        let {data: productData} = await responseProducts.json();

        let activeProductIds = productData.map(product => product.id);

        // prices are still "active" even if their product is "archived"
        priceData = priceData.filter((price) => activeProductIds.includes(price.product));

        try {
            productList.value = priceData
                .reduce((acc, priceItem) => {
                    const productItem = productData.find((product) => product.id === priceItem.product);
                    if (!productItem) return;

                    const yearly = priceItem?.recurring?.interval === 'year';

                    if (!priceItem) return acc;

                    const tokenCount = parseInt(priceItem.metadata.token_count);

                    const features = Object.entries(productItem?.metadata)
                        .filter(([translationKey]) => translationKey.indexOf('feature.') === 0)
                        .reduce((acc, [translationKey, value]) => {
                            acc.push({
                                key: translationKey,
                                value,
                            })
                            return acc;
                        }, []);

                    acc.push({
                        name: productItem.name,
                        active: productItem.active,
                        price: priceItem.unit_amount / 100,
                        price_month: yearly ? priceItem.unit_amount / 12 / 100 : priceItem.unit_amount / 100,
                        productId: productItem.id,
                        priceId: priceItem.id,
                        recurring: priceItem?.recurring?.interval ?? 'no',
                        token_count: tokenCount,
                        token_count_month: yearly ? tokenCount / 12 : tokenCount,
                        metadata: productItem.metadata,
                        features,
                    })

                    return acc
                }, []);
        } catch (e) {
            console.error(e);
        }
    }

    const hasSubscription = computed(() => subscription.value && Object.keys(subscription.value).length > 0);

    const trialEndsDate = computed(() => {
        const userCreatedDate = new Date(user.value.createdAt);

        return addDaysToDate(userCreatedDate, TRIAL_PERIOD_DAYS);
    });

    const isTrial = computed(() => {
        return !hasSubscription.value && isDate1AfterDate2(trialEndsDate.value, new Date());
    })

    const isActive = computed(() => {
        return hasSubscription.value || isTrial.value;
    })

    return {
        product,
        subscription,
        hasSubscription,
        userSubscriptionFeatureList,
        productList,
        isTrial,
        isActive,
        trialEndsDate,
        getSubscription,
        getProductById,
        getSubscriptionPriceList
    };
})
