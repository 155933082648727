<script setup lang="ts">

import {computed, onMounted, ref, watch} from "vue";

import {NavigationLink} from "@/components/Navigation/NavigationLinks.vue";
import {useI18n} from "vue-i18n";
import {useChatStore} from "@/store/chat.ts";
import {storeToRefs} from "pinia";
import {useRoute} from "vue-router";
import ContentItem from "@/components/templates/Chat/ContentItem/ContentItem.vue";
import {useChatGenerate} from "@/composables/useChatGenerate.ts";
import {CAlert} from "@coreui/vue";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {useTokenStore} from "@/store/token.ts";
import {useUIStore} from "@/store/ui.ts";
import {toast} from "vue3-toastify";
import ChatContentEmpty from "@/components/templates/Chat/ChatContentEmpty.vue";

const i18n = useI18n();
const { t } = i18n;
const chatStore = useChatStore();
const {promptInputHandler} = chatStore;
const {isActive, hasSubscription} = storeToRefs(useSubscriptionStore());
const {hasImages} = storeToRefs(useTokenStore());

const {messagesByChatId} = storeToRefs(chatStore);
const {product} = storeToRefs(useSubscriptionStore());

const {appReady} = storeToRefs(useUIStore());
const {chat, chatId} = defineProps<{
  chatId: string,
  chat: any,
}>();

const contentType = computed(() => {
  return chat?.value?.content_type;
})

const scrollAnchor = ref();

const route = useRoute();
const chatMessages = computed(() => {
  return messagesByChatId.value[route.params.chatId as string]
})

const {generatedData} = useChatGenerate();

const needsSubscription = computed(() =>
    !isActive?.value || (!hasSubscription.value && contentType?.value === 'image' && !hasImages?.value))

onMounted(async () => {
  await chatStore.getMessagesByChatId(route.params.chatId as string);

  scrollDown();
})

const scrollDown = () => {
  window.scrollTo({
    top: 1000000,
    
    behavior: 'instant'
  })
}

const onSubmitSuggestion = (suggestion: string) => {
  toast.success(i18n.t('suggestion.toast.success'));
  promptInputHandler.update(suggestion);
}

</script>

<template>
  <template v-if="appReady">
    <div class="content-wrapper d-flex flex-column align-items-center justify-content-end"
         :class="{ 'chat-content__no-tokens': needsSubscription }"
    >
      <template v-for="(message, index) in chatMessages">
        <ContentItem
            :role="message.role"
            :id="message.id"
            :feedbackOk="message.feedbackOk"
            :type="contentType"
            :status="message.status"
            :content="message.content"
            :is-last="index === chatMessages.length - 1"
            :suggestions="message.suggestions"/>
      </template>

      <div>
        <CAlert v-if="generatedData.error" color="danger">{{ $t(`error.${generatedData.error}`) }}</CAlert>
      </div>
      <ChatContentEmpty
          :type="contentType"
          v-if="chatMessages?.length === 0"/>

      <div id="anchor" ref="scrollAnchor"></div>
    </div>

  </template>
  <div class="content-wrapper"
       v-else>
    <CPlaceholder
        component="p" animation="glow">
      <CPlaceholder class="chat-content__skeleton" color="secondary" :xs="12"/>
    </CPlaceholder>
  </div>
</template>

<style lang="scss">
.chat-content {
  &__skeleton {
    margin: 20px;
    width: calc(100% - 40px) !important;
    height: 50vh !important;
  }

  &__suggestion-prompts {
    width: 100%;
    max-width: 900px;
  }

  &__fog {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(58, 57, 57, 0.29);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__no-tokens {
    padding-bottom: 250px !important;
  }
}

.content-wrapper {
  width: 100%;
  flex: 1 1 auto;
  padding-bottom: 50px;
  scroll-behavior: auto;

  li {
    cursor: text;
  }

  button.no-tokens {
    padding: 5px 15px;
    background: var(--cui-primary);
    border: none;
    color: white;
  }

  .subscription-type {
    background: var(--cui-body-color);
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 10px;
    color: white;
    font-size: 12px;
  }

  #anchor {
    overflow-anchor: auto;
    min-height: 80px;
    height: 80px;
    width: 100%;
  }

  * {
    overflow-anchor: none;
  }

  .no-response {
    font-size: 32px;
    color: rgba(208, 205, 205, 0.41);
    font-weight: 600;
  }

  @media(max-width: 768px) {
    padding-top: 50px;
  }
}

</style>
