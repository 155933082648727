<script setup lang="ts">
import {onMounted, ref} from "vue";
import {postRequest} from "@/services/http.service.ts";
import PlaceholderImage from "@/components/Atoms/PlaceholderImage/PlaceholderImage.vue";
import {Status} from '@shared/general.ts'

const {image} = defineProps<{
  image: string,
}>();

const imageUrl = ref('');
const imageItem = ref();
const imageStatus = ref(Status.WAITING);

const imageDimensions = ref({
      x: 0,
      y: 0,
    }
)

const onLoadImage = () => {
  imageDimensions.value.x = imageItem?.value?.naturalWidth;
  imageDimensions.value.y = imageItem?.value?.naturalHeight;

  imageStatus.value = Status.SUCCESS
}

const onErrorImage = () => {
  imageStatus.value = Status.ERROR
}

const intersected = ref(false);
const imageElementRef = ref(null);

const loadImage = async () => {
  const imageResponse = await postRequest('imageStorage/get', {
    image,
  })

  try {
    const blobImage = await imageResponse.blob();

    imageUrl.value = URL.createObjectURL(blobImage);
    imageStatus.value = Status.SUCCESS;

  } catch (e) {
    imageStatus.value = Status.ERROR;
  }
}

onMounted(async () => {
  const observer = new IntersectionObserver(entries => {
    const image = entries[0];

    if (image.isIntersecting) {
      if(intersected.value) return;

      loadImage();
      intersected.value = true;
    }
  });

  observer.observe(imageElementRef.value);
})
</script>

<template>
  <div class="image-secure" ref="imageElementRef">
  <span v-if="imageStatus === Status.PROCESSING || imageStatus === Status.WAITING">
     <PlaceholderImage />
  </span>
    <span v-else-if="imageStatus === Status.ERROR">{{ $t(`ui.image.load.error`) }}</span>
    <template v-else>
      <img class="content-image"
           @load="onLoadImage"
           @error="onErrorImage"
           ref="imageItem"
           alt="image"
           :src="imageUrl"/>
    </template>
    <!--  <CBadge :class="{ loaded: imageStatus === Status.SUCCESS}" class="content-image__badge">{{ `${imageDimensions.x} x ${imageDimensions.y}` }}</CBadge>-->

  </div>
</template>

<style lang="scss">

.content-image {
  border-radius: 5px !important;
  width: 100%;
  max-width: 512px;
  align-self: start;

  &__badge {
    align-self: flex-start;
    margin-top: 5px;
    background: var(--cui-body-color);
    visibility: hidden;

    &.loaded {
      visibility: visible;
    }
  }
}


</style>
