import {useMutation, useQuery, useQueryClient, UseQueryReturnType} from '@tanstack/vue-query'
import {supabase} from '@/supabase.ts'
import {ITemplateInputField, TemplateInputField} from '@/class/TemplateInputField.ts'
import {TemplateInput} from '@/class/TemplateInput.ts'
import {QueryClient} from '@tanstack/vue-query'
import {ITemplateFieldValue, TemplateInputFieldValue} from '@/class/TemplateInputFieldValue.ts'
import {Ref} from 'vue'

export const useTemplateInput = () => useQuery({
    queryKey: ['templateInput'],
    queryFn: async (): Promise<{}> => {
        const response = await supabase
            .from('template_input')
            .select(
                'id, name, response,created_at, template(key)'
            )
            .order('created_at', {ascending: false});

        if (response.error) {
            return [{}] as ITemplateInputField[];
        }

        return response.data.reduce((acc, curr) => {
            acc[curr.id] = TemplateInput.fromDbResponse(curr);

            return acc;
        }, {});
    }
})

export const useTemplateList = () => useQuery({
    queryKey: ['templateList'],
    refetchOnMount: false,
    queryFn: async () => {
        console.log('useTemplateList called');
        const { data } = await supabase
            .from('template')
            .select('id,key,category:template_category(id,name)')
            .order('order', {ascending: true});

        return data;
    }
})

export const useTemplateCategoryList = () => useQuery({
    queryKey: ['templateCategoryList'],
    refetchOnMount: false,
    queryFn: async () => {
        const { data, error } = await supabase
            .from('template_category')
            .select('id,name')
            .order('order');

        if (error) return [];

        return data;
    }
});

export const useTemplateInputValues = (inputId: Ref): UseQueryReturnType<ITemplateFieldValue[], unknown> => {
    return useQuery({
        queryKey: ['templateInput', inputId.value],
        queryFn: async () => {
            const response = await supabase
                .from('template_input_value')
                .select(`
                    template_input(id),
                    template_input_field(id,name),
                    value
                `)
                .eq(' template_input_id', inputId.value)

            if (response.error) {
                return [{}] as ITemplateFieldValue[];
            }

            return response.data.map((item) => TemplateInputFieldValue.fromDbResponse(item)) || [];
        }
    })
};

export const useTemplateInputFields = (templateKey: string) => useQuery({
    queryKey: ['templateInputFields'],
    queryFn: async (): Promise<ITemplateInputField[]> => {
        const response = await supabase
            .from('template')
            .select(`
                id,
                key,
                template_input_field_map(id, required, order, template_input_field(id,name,template_input_field_type(name),
                template_input_field_option(name)))
            `)
            .eq('key', templateKey)
            .single();

        if (response.error) {
            return [{}] as ITemplateInputField[];
        }

        return TemplateInputField.fromDbResponse(response.data)
            .sort((a,b) => a.order - b.order);
    }
})

export function incrementTemplateInputValue(queryClient: QueryClient, templateInputId: number, chunk: string) {
    queryClient.setQueryData(['templateInput'], (oldData) => {
        return {
            ...oldData,
            [templateInputId]: {
                ...oldData[templateInputId],
                value: oldData[templateInputId].value + chunk,
            },
        };
    });
}

export const updateTemplateInputValue = async (queryClient: QueryClient, templateInputId: number, value: string) => {
    queryClient.setQueryData(['templateInput'], (oldData) => {
        return {
            ...oldData,
            [templateInputId]: {
                ...oldData[templateInputId],
                value,
            },
        };
    });
}

export function useDeleteItem() {
    const queryClient = useQueryClient()

    // Define the mutation using useMutation with the fetch-based delete function as an anonymous function
    const mutation = useMutation<void, Error, number>({
        mutationFn: async (templateId: number): Promise<void> => {
            const {error} = await supabase
                .from('template_input')
                .delete()
                .eq('id', templateId);

            if (error) {
                throw new Error('Failed to delete item')
            }
        }
    })

    const deleteItemAsync = async (templateId: number): Promise<void> => {
        try {
            await mutation.mutateAsync(templateId)
            // Invalidate queries to refresh the list after deletion
            await queryClient.invalidateQueries({
                queryKey: ['templateInput']
            })
        } catch (error) {
            console.error('Error deleting item:', error)
        }
    }

    return {
        deleteItemAsync,
        ...mutation,
    }
}
