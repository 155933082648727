<script setup lang="ts">

import {NavigationLink} from "@/components/Navigation/NavigationLinks.vue";
import {useI18n} from "vue-i18n";
import {storeToRefs} from "pinia";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {useUtils} from "@/composables/useUtils.ts";
import SuggestionContainer from "@/components/SuggestionContainer/SuggestionContainer.vue";
import {computed} from "vue";

const {type} = defineProps<{
  type: string
}>()

const i18n = useI18n();
const { t } = i18n;
const { translationExists } = useUtils();
const {product} = storeToRefs(useSubscriptionStore());

const suggestionCount = computed(() =>
    Object.keys(i18n.messages.value.en).filter((translationKey) =>
        translationKey.indexOf(`suggestion.${type}.item.`) !== -1).length)

const suggestionsByTranslations = computed(() => {
  return Array.from({ length: suggestionCount.value }, (_, index) =>
      t(`suggestion.${type}.item.${index + 1}`))
});

</script>

<template>
  <p class="no-response d-flex flex-column text-center w-100">
        <span class="d-flex justify-content-center align-items-center">
          {{ $t(`templates.noContent`) }}
          <span class="subscription-type">{{ product?.name }}</span>
        </span>
  </p>

  <section
      id="tour-content-text-suggestions"
      class="chat-content__suggestion-prompts" v-if="suggestionsByTranslations.length > 0">
    <CContainer>
      <h4 class="text-center mb-5 mt-5">💡{{
          (translationExists(`suggestion.${type}.header`) ?
                  $t(`suggestion.${type}.header`) :
                  $t('suggestion.generic.header')
          )
        }}</h4>
      <p class="text-center mb-5 mt-5">{{
          (translationExists(`suggestion.${type}.text`) ?
                  $t(`suggestion.${type}.text`) :
                  $t('suggestion.generic.text')
          )
        }}</p>

      <SuggestionContainer
          :suggestions="suggestionsByTranslations"
          :type="type"
      />
    </CContainer>
  </section>
</template>

<style lang="scss">
html {
}

.chat-content {
  &__skeleton {
    margin: 20px;
    width: calc(100% - 40px) !important;
    height: 50vh !important;
  }

  &__suggestion-prompts {
    width: 100%;
    max-width: 900px;
  }

  &__fog {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(58, 57, 57, 0.29);
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  &__no-tokens {
    padding-bottom: 250px !important;
  }
}

.content-wrapper {
  width: 100%;
  flex: 1 1 auto;
  padding-bottom: 50px;
  scroll-behavior: auto;

  li {
    cursor: text;
  }

  button.no-tokens {
    padding: 5px 15px;
    background: var(--cui-primary);
    border: none;
    color: white;
  }

  .subscription-type {
    background: var(--cui-body-color);
    padding: 4px 10px;
    margin-left: 10px;
    border-radius: 10px;
    color: white;
    font-size: 12px;
  }

  #anchor {
    overflow-anchor: auto;
    min-height: 80px;
    height: 80px;
    width: 100%;
  }

  * {
    overflow-anchor: none;
  }

  .no-response {
    font-size: 32px;
    color: rgba(208, 205, 205, 0.41);
    font-weight: 600;
  }

  @media(max-width: 768px) {
    padding-top: 50px;
  }
}

</style>
