import {defineStore} from "pinia";
import {computed, ref} from "vue";
// import {supabase} from "@/supabase.ts";
import {ProfileRow} from "@/types/supabase.types.ts";
import {supabase} from '@/supabase.ts'

export enum AvatarType {
    URL,
    INITIALS
}

export interface Avatar {
    type: AvatarType,
    src?: string,
    initials?: string,
}

export const useUserStore = defineStore('user', () => {
    const profile = ref<ProfileRow & { email, createdAt }>({} as ProfileRow & { email, createdAt });

    const avatar = computed<Avatar>(() => {
        
        if (profile?.value?.avatar_url) {
            return {
                type: AvatarType.URL,
                
                src: profile?.value?.avatar_url
            }
        } else {
            const initials = profile?.value?.full_name?.split(' ')
                .map((namePart: string) => namePart?.[0]);

            return {
                type: AvatarType.INITIALS,
                
                initials: initials?.join('') || ''
            }
        }
    });

    const updateUser = async (extraUserInfo) => {
        profile.value = {
            ...profile.value,
            ...extraUserInfo,
        }
    }

    return {user: profile, avatar, updateUser};
})