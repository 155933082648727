import {defineStore} from "pinia";
import {reactive, ref} from "vue";

interface ITemplateGeneratingProgress {
    content: boolean;
}

export const useTemplateStore = defineStore('template', () => {
    const generatingInProgress = reactive<Record<string, ITemplateGeneratingProgress>>({});

    const templateCategoryList = ref<Record<string, any>>([]);

    return {
        templateCategoryList,
        generatingInProgress,
    }
})
