import { postRequest, type IInvoiceDetails } from "./http.service.ts";

export function createStripeSubscription(priceId: string, invoiceDetails?: IInvoiceDetails) {
    return postRequest(`stripe/subscription/create`, {
        priceId,
        ...invoiceDetails,
    });
}

export function purchaseExtraTokens(priceId: string) {
    return postRequest(`stripe/token/purchase`, {
        priceId,
    });
}

export function getPriceList() {
    return postRequest(`stripe/price/list`);
}

export function getProductList() {
    return postRequest(`stripe/product/list`);
}

export function getStripeSubscription() {
    return postRequest('stripe/subscription/get');
}

export function getStripeProduct(productId: string) {
    return postRequest('stripe/product/get', {
        productId,
    });
}

export function buyTokens(productId: string) {
    return postRequest('stripe/product/get', {
        productId,
    });
}