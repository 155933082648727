<script setup lang="ts">
import TemplateInput from "@/components/templates/Template/TemplateInput.vue";
import TemplateOutput from "@/components/templates/Template/TemplateOutput.vue";
import {useRoute, useRouter} from "vue-router";
import {computed, ref, watch} from "vue";

import {useTemplateGenerate} from "@/composables/useTemplateGenerate.ts";
import {
  useTemplateInput,
  useTemplateInputFields,
  useTemplateInputValues,
  useTemplateList
} from '@/queries/templateQuery.ts'

const router = useRouter();
const route = useRoute();
const templateKey = computed(() => route.params.templateKey  || ``);
const inputIdRef = computed(() => route.params.outputId);

const { data: templateList } = useTemplateList();
const { data: templateInputList } = useTemplateInput();
const templateGenerate = useTemplateGenerate();

const { data: inputValues } = useTemplateInputValues(inputIdRef);
const template = computed(() => templateList?.value?.find(template => template?.key === templateKey.value));
const templateResponse = computed(() => {
  return templateInputList.value?.[inputIdRef.value as string];
});

watch(inputValues, (val) => {
  console.log('inputValues', val)

}, { immediate: true });

const { data: formFields } = useTemplateInputFields(templateKey.value as string)

watch(() => formFields, (val) => {
 console.log('formFields', val);
}, { immediate: true });

watch(() => route.params.outputId, (outputId) => {
  if (!outputId) return '';

  inputIdRef.value = outputId;
}, { immediate: true });


// watch(templateList, () => {
//   initializeTemplate();
// })

const onInputSubmit = (values) => {
  templateGenerate.generate(template.value.id, values, ({ inputId }) => {
    router.push(`/template/${templateKey.value}/${inputId}`);
    inputIdRef.value = inputId;
  });
}

</script>

<template>
  <div class="d-flex min-vh-100">
    <TemplateInput :inputs="formFields"
                  :template="template"
                  :values="inputValues"
                  @submit="onInputSubmit"
                  class="d-flex p-4 lg:min-w-[500px] min-w-1/2" />
<!--    <CPlaceholder-->
<!--        v-else-->
<!--        class="d-flex p-4 lg:min-w-[500px] min-w-1/2"-->
<!--        component="p"-->
<!--        animation="glow"-->
<!--    >-->
<!--      <CPlaceholder color="secondary" :xs="12" size="lg"/>-->
<!--      <CPlaceholder color="secondary" :xs="12" size="m"/>-->
<!--    </CPlaceholder>-->
    <div class="d-flex w-[1px] bg-slate-100"></div>
    <TemplateOutput :value="templateResponse"
                   class="d-flex flex-fill p-4 min-w-1/2"/>
  </div>
</template>
