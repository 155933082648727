<script setup lang="ts">
import {onMounted, provide, ref, watch} from "vue";
import {useChatStore} from "@/store/chat.ts";
import {supabase} from "@/supabase.ts";
import {useUserStore} from "@/store/user.ts";
import {useTokenStore} from "@/store/token.ts";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {H} from "highlight.run";
import {ENVIRONMENT} from "@/constants/variables.ts";
import {useUIStore} from "@/store/ui.ts";
import {storeToRefs} from "pinia";
import {useGtag} from "vue-gtag-next";
import {useRouter} from "vue-router";
import {useTemplateStore} from "@/store/template.ts";

let isLoggedIn = ref(false);
const userStore = useUserStore()
const chatStore = useChatStore()
const subscriptionStore = useSubscriptionStore()
const uiStore = useUIStore()

let prevState = '';
let currState = '';

const {isLatestVersion, appReady} = storeToRefs(uiStore);
const updateModalOpen = ref(false)
const {event} = useGtag();
const router = useRouter();

watch(isLatestVersion, () => {
  setTimeout(() => {
    updateModalOpen.value = !isLatestVersion.value
  }, 100)
}, {immediate: true})

supabase.auth.onAuthStateChange(async (evn, session) => {
  console.log('called onAuthStateChange', evn, session);
  // if (evn === 'SIGNED_IN') {
  //   await router.push({ name: 'introWelcome' });
  //   return;
  // }

  const user = session?.user;

  console.log('user', user);
  currState = user?.aud;
  if (prevState === currState) return;

  prevState = currState;

  if (session) {
    if (ENVIRONMENT !== 'local') {
      H.identify(user.email, {
        id: user.id,
      });
    }

    userStore.updateUser({
      email: user.email,
      uid: user.id,
      createdAt: user.created_at,
    });

    appReady.value = true;
  }

  isLoggedIn.value = !!user;
})

onMounted(async () => {
  await Promise.all([
    chatStore.subscribeResponse(),
    chatStore.getChatList(),
    subscriptionStore.getSubscriptionPriceList(),
    subscriptionStore.getSubscription(),
  ]);
});

provide('isLoggedIn', isLoggedIn);
const appVersion = import.meta.env.VITE_APP_VERSION;

</script>

<template>
  <router-view></router-view>

  <!--  <ConfirmModal v-model="updateModalOpen"-->
  <!--                :closeable="false"-->
  <!--                :header="$t('ui.update.header')"-->
  <!--                :text="$t('ui.update.text')"-->
  <!--                :confirm-text="$t('ui.update.confirm')"-->
  <!--                :on-confirm="updateVersion"-->
  <!--  />-->

</template>

