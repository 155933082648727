import {useQuery} from '@tanstack/vue-query'
import {getPriceList, getProductList} from '@/services/stripe.service.ts'

export const useSubscriptionPriceList = () => useQuery({
    queryKey: ['subscriptionPriceList'],
    queryFn: async () => {
        console.log('called subscriptionPriceList');
        const responsePrice = await getPriceList();
        const responseProducts = await getProductList();

        let {data: priceData} = await responsePrice.json();
        let {data: productData} = await responseProducts.json();

        console.log('priceData', priceData);
        console.log('productData', productData);
        let activeProductIds = productData.map(product => product.id);

        // prices are still "active" even if their product is "archived"
        const productList = priceData
            .filter((price) => activeProductIds.includes(price.product))
            .reduce((acc, priceItem) => {
                const productItem = productData.find((product) => product.id === priceItem.product);
                if (!productItem) return;

                const yearly = priceItem?.recurring?.interval === 'year';

                if (!priceItem) return acc;

                const tokenCount = parseInt(priceItem.metadata.token_count);

                const features = Object.entries(productItem?.metadata)
                    .filter(([translationKey]) => translationKey.indexOf('feature.') === 0)
                    .reduce((acc, [translationKey, value]) => {
                        acc.push({
                            key: translationKey,
                            value,
                        })
                        return acc;
                    }, []);

                acc.push({
                    name: productItem.name,
                    active: productItem.active,
                    price: priceItem.unit_amount / 100,
                    price_month: yearly ? priceItem.unit_amount / 12 / 100 : priceItem.unit_amount / 100,
                    productId: productItem.id,
                    priceId: priceItem.id,
                    recurring: priceItem?.recurring?.interval ?? 'no',
                    // token_count: tokenCount,
                    // token_count_month: yearly ? tokenCount / 12 : tokenCount,
                    metadata: productItem.metadata,
                    features,
                })

                return acc
            }, []);


        const sortByTokenCount = (a, b) => a.token_count_month - b.token_count_month

        return ({
            month: productList
                .filter((productItem) => productItem.recurring === 'month')
                .sort(sortByTokenCount),
            year: productList
                .filter((productItem) => productItem.recurring === 'year')
                .sort(sortByTokenCount),
            no: productList
                .filter((productItem) => productItem.recurring === 'no')
                .sort(sortByTokenCount),
        })
    }
})
